@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-content),
:global(.image-gallery-slide-wrapper),
:global(.image-gallery-swipe),
:global(.image-gallery-slides),
:global(.image-gallery-slide) {
  width: 100%;
  /* height: 100%; */
}

.image-gallery-slide-wrapper {
  background-color: var(--colorBlack);
}

.root {
  position: relative;
  /* width: 100%; */
  /* height: 100vh; */
  padding: 0;
  /* background-color: var(--colorBlack); */
}

.root .navLeft,.root .navRight{
  opacity: 0;
  transition: opacity 0.2s;
}
.root:hover .navLeft,.root:hover .navRight{
  opacity: 1;
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
}

.arrowHead {
  fill: var(--colorGrey300);
  stroke: var( --colorGrey300);
}

.navLeft,
.navRight {
  position: absolute;
  /* width: 10vw; */
  /* height: 100%; */
  border: 0;
  padding: 0;
  z-index: 1;
  top: calc(50% - 15px);
  transform: scale(0.9);

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgba(0,0,0,0.08);
  
  &:hover {
    cursor: pointer;
    transform: scale(1);

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
    & .arrowHead {
      fill: var(--colorGrey500);
      stroke: var(--colorGrey500);
    }
  }

  &:active {
    transform: scale(0.9);
  }
}

.navLeft {
  left: 0;
  justify-content: flex-start;
  padding-left: 12px;
}

.navRight {
  right: 0;
  justify-content: flex-end;
  padding-right: 12px;
}

.navArrowWrapper {
  display: none;

  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.25s;

  /* background-color: transparent; */

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.8);
  
  @media (--viewportMedium) {

  }
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.imageIndex {
  /* Fonts */
  composes: h4 from global;
  font-weight: var(--fontWeightSemiBold);

  /* Position and dimensions */
  position: absolute;
  top: 21px;
  left: 20px;
  margin: 0;

  /* Colors */
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.sliderDotsContainer{
  position: absolute;
  bottom: 8px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  display: flex;
}
.imageDots{
  align-items: flex-end;
  display: flex;
  list-style-type: none;
  transition: all 0.2s;
}
.sliderDot {
  background: #FFFFFF;
  border-radius: 50%;
  transition: opacity 0.2s cubic-bezier(0.455,0.03,0.515,0.955);
  margin-left: 2.5px;
  margin-right: 2.5px;
  text-align: center;

  display: inline-block;
  transform:scale(1);
  opacity: 0.6;
  height: 6px;
  min-width: 6px;
  width: 6px;
}
.activeSliderDot{
  opacity: 1;
}
.sliderDotsCenter{
  max-width: 56px;
  overflow: hidden;
  justify-content: center;
}