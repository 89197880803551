/* Slider container */
.categoryScrollerContainner {
  overflow: hidden;
  position: relative;
  height: 60px;
}

.categoryIconsContainner {
  flex-grow: 1;
  text-align: center;
  padding: 0 30px;
  position: relative;
  width: 100%;
}

.categoriesList {
  flex-grow: 1;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  align-items: flex-end;
}

.scrollerContainerRight {
  width: 40px;
  height: 50px;
  position: absolute;
  right: 28px;
  background-image: linear-gradient(to right, rgb(255 255 255 / 0), white 40px);
  z-index: 1;
  transition: all 0.3s;
}

.scrollerContainerLeft {
  width: 40px;
  height: 50px;
  position: absolute;
  left: 40px;
  background-image: linear-gradient(to left, rgb(255 255 255 / 0), white 40px);
  z-index: 1;
  transition: all 0.3s;
}

/* Arrows for navigation */
.navLeft,
.navRight {
  position: absolute;
  top: calc(50% - 15px);
  transform: scale(0.9);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  border-color: rgba(0, 0, 0, 0.08);
}

.navLeft {
  left: -15px;
  justify-content: flex-start;
}

.navRight {
  right: -15px;
  justify-content: flex-end;
}

/* Arrow styles */
.navArrowWrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.25s;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
}

.navLeft:hover,
.navRight:hover {
  cursor: pointer;
  transform: scale(1);
}

.navArrowWrapper:hover {
  background-color: rgba(255, 255, 255, 1);
}

.arrowHead {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

/* Hover effect on arrows */
.navLeft:hover .arrowHead,
.navRight:hover .arrowHead {
  fill: var(--colorGrey500);
  stroke: var(--colorGrey500);
}

/* Active state for arrows */
.navLeft:active,
.navRight:active {
  transform: scale(0.9);
}

/* ShowMapSwitcher styles */
.showMapSwitcher {
  display: inline-flex;
  flex-direction: row;
  border: solid 1px var(--colorGrey100);
  border-radius: 18px;
  height: 40px;
  width: 150px;
  align-items: center;
  vertical-align: middle;
  padding: 0 10px;
  justify-content: space-between;
  margin: 10px 0px;
}

/* Map switcher inner container */
.mapSwitcherContainer {
  padding-left: 10px;
  padding-top: 4px;
}